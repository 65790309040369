<template>
    <v-card >
         <s-crud
             :config="config"
            title="Registrar Tiempo por Calibre para el cultivo"
            :filter="filter"
            add
            remove
            @save="saveTc($event)"
            >
            <template v-slot="props">
                <v-card>               
                    <v-container>
                            <v-row>
                                <v-col cols="4" class="">
                                    <s-select-definition
                                        :dgr="groupID"
                                        :def="1266"
                                        @change="change($event)"
                                        hidden
                                        
                                    >
                                    </s-select-definition>
                                    <s-select-definition  
                                        v-model="props.item.TypeCultive"
                                        :def="1173"
                                        return-object
                                        label="Seleccione Cultivo"
                                        @input="inputCultive($event)">
                                    </s-select-definition>

                                </v-col>
                                <v-col cols="4" class="">
                                    <s-select
                                        v-model="props.item.TypeCaliber"
                                        :items="items"
                                        item-value="TypeCaliber"
                                        item-text="TypeCaliberName"
                                        label="Calibres">
                                        
                                    </s-select>
                                    <!-- <s-select-definition 
                                        v-model="props.item.TypeCaliber"
                                        label="Calibre" 
                                        autocomplete 
                                        :def="1266" 
                                        :dgr="34"></s-select-definition> -->

                                </v-col>
                                <v-col cols="4" class="">
                                    <s-time 
                                        v-model="props.item.TicTime"
                                        label="Tiempo" 
                                        number
                                        ></s-time>

                                </v-col>
                            </v-row>
                    </v-container>
            
                </v-card> 
          
            </template>
          
         </s-crud>
    </v-card>
</template>

<script>

    import _ServiceTimeCaliber from "../../../services/FreshProduction/TimeCaliber"

    export default {
        data() {
            return {
                config: {
                    service: _ServiceTimeCaliber,
                    model: {
                        "TicID" : "ID",
                        TicTime: "time",
                    },
                    headers:[
                        { text:"ID", value: "TicID" },
                        { text:"Tiempo", value: "TicTime" },
                        { text:"Cultivo", value: "TypeCultiveName" },
                        { text:"Calibre", value: "TypeCaliberName" },
                    ]
                },              
                filter: {/*		TypeCrop	TypeCaliber	TypeGroup */},
                groupID: 0,
                items: [],
                TypeCult : 0,
                VrtID: 0,
                objTypeCultive: {}
            }
        },
        methods: {
            change(val) {
				this.items = val;
                console.log('mis items',this.items)

				this.items.forEach(element => {
					element.TypeCaliber = element.DedValue;
					element.TypeCaliberName = element.DedDescription;

					delete(element.DedAbbreviation)
					delete(element.DedHelper)
					delete(element.DedID)
					delete(element.DefID)
					delete(element.DgdID)
					delete(element.DgrID)
					delete(element.SecStatus)
					delete(element.DedValue)
					delete(element.DedDescription)
				});
				console.log("items", this.items);
			},
            inputCultive(item){
                
				/*console.log(this.itemSelected, this.selectedPermissions);*/
				if(item !== undefined){
					if(!isNaN(item.DedAbbreviation)){
                        this.TypeCult = item.DedValue
						this.groupID = parseInt(item.DedAbbreviation)
						this.VrtID = parseInt(item.DedValue)
						console.log("cultivo",item);
                        console.log('tipo cultivo', this.TypeCult);
                        console.log("grupo", this.groupID);
						// this.initialize()
					}else{
						this.items = []
					}
					
				}
				
			},
            saveTc(item){
                
                item.TypeGroup = this.groupID;
                item.TypeCultive = this.TypeCult;
                
                // var id_ = item.TypeCrop.DefID;
                // item.TypeCrop = id_;

                console.log("itemddddd", item);
                item.save();
            }
        },
    }
</script>